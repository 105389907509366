// user

import fetchApi from './fetchApi'

/**
 * @method POST
 * @body email / password
 *
 * @returns UserObject BenefitsArray SelectionsArray
 */
export const V2_LOGIN = "/user/login"
export const BEAM_LOGIN = '/login'
export const BEAM_SSO_LOGIN = '/login/sso'
export const LOGIN_SETUP = '/login/setup'
export const LOGIN_WITH_2FA = '/login/mfa'
export const V2_LOGOUT = 'user/logout'
export const BEAM_LOGOUT = `/logout`
export const USER = '/user'
export const SCHEME = 'scheme'
export const CHECK_PASSWORD = 'check-password'
export const SET_PASSWORD = 'set-password'
export const CHANGE_PASSWORD = 'change-password'
export const USER_PROFILE = 'user/profile'
export const USER_PATCH_TERMS = '/user/policies'
export const USER_FAMILY = '/user/family'
export const ADD_FAMILY = id => `user/${id}/familymember`
export const PAYROLL = id => `client/${id}/payroll-report`
export const PROVIDER = id => `client/${id}/provider-report`
export const USER_CLIENT = '/client/user'
export const CONTACT = 'contact'
export const BEAM_CONTACT = 'cms-content/contact'
export const SCHEME_BRANDING = payload =>
	`scheme/branding/${payload.mode}/${payload.domain}`
export const V2_AVATAR_SET = schemeId => `scheme/branding/avatar-set/${schemeId}`
export const GET_V2_CORE_BRANDING_URLS = 'scheme/branding/core-branding-urls'
export const USER_REFRESH = 'user/refresh'

// activation
export const TEST_ACTIVATION_URL = id => `user/activate/${id}`
export const ACTIVATION_DOB = 'user/activate/dob'
export const ACTIVATE_MAGIC_LINK = `user/activate`

// benefits
export const GET_V2_SELECTIONS_AND_BENEFITS = '/selections';
export const GET_BENEFITS = '/benefits/user/eligible'
// export const GET_V2_BENEFIT = id => `benefit/${id}`
export const GET_BENEFIT = id => `/benefit/${id}`
export const GET_CCV_BALANCE = 'ccv/balance'
export const GET_CCV_MANAGE = 'ccv/manage'
export const GET_SELECTION = id => `selection/${id}`
export const BENEFIT_FAQS = id => `benefit/${id}/related_FAQ`
export const RELATED_BENEFITS = id => `benefit/${id}/related_benefits`
export const STATEMENT = id => `benefit-group/${id}/contributions`
export const TERMS = payload =>
	`benefit/${payload.benefitId}/terms/${payload.termId} `
export const OPT_OUT = id => `selection/${id}`
export const ADD_BENEFIT = id => `benefit/${id}/selection`
export const EDIT_BENEFIT = id => `selection/${id}`

export const GET_V2_BENEFIT_AND_SELECTION = id => `benefit/${id}/with-selection`

// content
export const GET_TERMS = 'policies/terms-conditions'
export const GET_POLICY = 'policies/privacy-policy'
export const GET_COOKIE_POLICY = 'policies/our-cookie-policy'
export const BEAM_GET_CAROUSEL = 'carousel'
export const GET_FAQS = 'cms-content/faqs'

// reset password
export const CHECK_TOKEN = 'check-magic-token'
export const REQUEST_PASSWORD_REST = `user/request-password-reset`
export const PASSWORD_RESET = id => `user/request-password-reset/${id}`

// upload data

export const UPLOAD_TEMPLATE = id => `client/${id}/data-load-template`
export const UPLOAD_DATE = id => `client/${id}/date-last-upload`
export const SUBMIT_UPLOAD = id => `client/${id}/data-loads`

// total benefits

export const TOTAL_BENEFITS_SUMMARY = `selections/costs/overview`
export const BEAM_TOTAL_BENEFITS_CARD = 'user/selections/mtb/card'
export const BEAM_TOTAL_BENEFITS_PAGE = 'user/selections/mtb/page'

// life events
export const GET_AVAILABLE_LIFE_EVENTS = 'life-event/get-available'
export const GET_USER_LIFE_EVENTS = 'life-event/get-user-events'
export const ADD_LIFE_EVENT = eventTypeId => `life-event/add/${eventTypeId}`

// Employee Data
export const GET_ALL_EMPLOYEES_FOR_CLIENT = (id) => `v2/client/${id}/employees`
export const GET_ALL_EMPLOYEES_FOR_CLIENT_PARAMS = (id, params) => `v2/client/${id}/employees?${params}`
export const GET_EMPLOYEES_FOR_CLIENT_REPORT = (clientId) => `v2/client/${clientId}/employees/download-report`
export const GET_EMPLOYEES_FOR_CLIENT_REPORT_PARAMS = (clientId, params) => `v2/client/${clientId}/employees/download-report?${params}`
export const GET_EMPLOYEE_DATA = (clientId, employeeId) => `client/${clientId}/employees/${employeeId}`
export const GET_LIFE_EVENT_REPORT = (clientId) => `client/${clientId}/employees/life-events`

// Benefit takeup
export const GET_BENEFIT_TAKEUP_DATA = (clientId) => `client/${clientId}/benefit-takeup`
export const GET_TAKEUP_FOR_BENEFIT = (clientId, benefitId) => `client/${clientId}/benefit-takeup/${benefitId}`

// Approval
export const GET_SELECTIONS_FOR_APPROVAL = "v2/scheme/selections/pending/";
export const GET_SELECTIONS_FOR_APPROVAL_FOR_BENEFIT = benefitId => `v2/scheme/selections/${benefitId}/pending/`;
export const UPDATE_SELECTION_STATUS = "v2/scheme/selections/status/";

// Masquerade
export const GET_MAGIC_LINK_EMPLOYER_MASQUERADE = (schemeId, employeeId) => `masquerade/employer/${schemeId}/${employeeId}`

// 2FA
export const SET_MFA = (userID) => `v2/user/${userID}/mfa`;
export const VALIDATE_MFA = (userID) => `v2/user/${userID}/mfa/validate`;
export const VALIDATE_MFA_ENFORCED = 'employee/mfa/validate/enforced';
export const TWO_FA_TABLE = (clientId) => `v2/client/${clientId}/employees/two-factor-authentication`
export const TWO_FA_TABLE_PARAMS = (clientId, params) => `v2/client/${clientId}/employees/two-factor-authentication?${params}`
export const TWO_FA_ENFORCE = (clientId) => `v2/client/${clientId}/employees/two-factor-authentication/enforcement-setting`

export function getAPIBaseURL(endpoint)
{
	// eslint-disable-next-line default-case
	switch(endpoint) {
		case BEAM_LOGIN:
		case ACTIVATE_MAGIC_LINK:
		case BEAM_SSO_LOGIN:
		case BEAM_LOGOUT:
		case LOGIN_SETUP:
		case LOGIN_WITH_2FA:
		case USER:
		case GET_BENEFITS:
		case GET_POLICY:
		case GET_TERMS:
		case GET_COOKIE_POLICY:
		case USER_FAMILY:
		case USER_PATCH_TERMS:
		case USER_CLIENT:
		case BEAM_GET_CAROUSEL:
		case BEAM_CONTACT:
		case BEAM_TOTAL_BENEFITS_CARD:
		case BEAM_TOTAL_BENEFITS_PAGE:
		case GET_SELECTIONS_FOR_APPROVAL:
		case UPDATE_SELECTION_STATUS:
		case VALIDATE_MFA_ENFORCED:
			return BEAM_API_URL;
	}

	const paramaterEndpoint = endpoint
		.replace(/([0-9]+)/g).replace('vundefined', 'v2')
		.replace(/\?(?:.*&)?(search|filter|page|sortBy|range|type)\b[^&]*/g, '?undefined')

	// BEAM function based endpoints
	switch (paramaterEndpoint)
	{
		case GET_BENEFIT():
		case GET_EMPLOYEE_DATA():
		case GET_ALL_EMPLOYEES_FOR_CLIENT():
		case GET_ALL_EMPLOYEES_FOR_CLIENT_PARAMS():
		case GET_EMPLOYEES_FOR_CLIENT_REPORT():
		case GET_EMPLOYEES_FOR_CLIENT_REPORT_PARAMS():
		case GET_MAGIC_LINK_EMPLOYER_MASQUERADE():
		case GET_LIFE_EVENT_REPORT():
		case GET_SELECTIONS_FOR_APPROVAL_FOR_BENEFIT():
		case SET_MFA():
		case VALIDATE_MFA():
		case TWO_FA_TABLE_PARAMS():
		case TWO_FA_TABLE():
		case TWO_FA_ENFORCE():
			return BEAM_API_URL;
	}

	return API_URL

}
